import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Dialog, FlatButton, ListItem } from 'material-ui'
import { FilterList } from 'material-ui-icons'

// TODO: use causes from API
import causes from 'data/ge-data/causes.json'

import { setGiftSearchFilterByKey } from 'store/actions/giftSearch'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import DefaultButton from './DefaultButton'

const FocusHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)
  const [selected, setSelected] = useState([])

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['focus']

  const handleClose = () => {
    setShowForm(false)
  }

  const handleOpen = e => {
    e.stopPropagation()
    setSelected(filters['focus'] || [])
    setShowForm(true)
  }

  const handleSubmit = e => {
    dispatch(setGiftSearchFilterByKey({ focus: selected.length > 0 ? selected : null }))
    handleClose()
  }

  return (
    <div>
      <FlatButton
        title="Gift recipient focus"
        onClick={handleOpen}
        className={`ge-header-button ${isActive ? 'active' : ''}`}
      >
        <div className="tw-inline-flex tw-items-center tw-text-dark">
          <FilterList />
          <span className="tw-ml-1">{column.columnDef.header}</span>
        </div>
      </FlatButton>
      <Dialog
        open={showForm}
        onClose={handleClose}
        onRequestClose={handleClose}
        onBackdropClick={handleClose}
        className="Explorer ge-dialog"
      >
        <div className="tw-relative tw-flex tw-flex-col tw-w-full">
          <p className="tw-w-full tw-flex-none tw-tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold">
            {t.explorer.filter_by_focus}
          </p>
          <div className="tw-overflow-scroll tw-grow">
            {causes.map(cause => {
              const selectedIndex = selected.findIndex(s => s === cause.name)
              const isSelected = selectedIndex >= 0
              return (
                <ListItem
                  key={cause.id}
                  nestedLevel={0}
                  primaryText={cause.name}
                  primaryTogglesNestedList={false}
                  leftCheckbox={
                    <Checkbox
                      className={`ge-focus-checkbox ${isSelected ? 'active' : ''}`}
                      checked={isSelected}
                      onCheck={() => {
                        if (isSelected) {
                          const newArray = [...selected]
                          newArray.splice(selectedIndex, 1)
                          setSelected(newArray)
                        } else {
                          setSelected([...selected, cause.name])
                        }
                      }}
                    />
                  }
                />
              )
            })}
          </div>
          <div className="tw-pt-3 tw-flex tw-justify-end tw-flex-none tw-border tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-grey">
            <DefaultButton onClick={handleSubmit} label={t.explorer.apply} />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default FocusHeader
