import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Dialog, FlatButton } from 'material-ui'
import { Star } from 'material-ui-icons'

import { resetGiftSearch, syncGiftSearchURL, updateGiftSearchURL } from 'store/actions/giftSearch'
import { deleteSavedSearch, getSavedSearches } from 'store/actions/savedSearches'

import DefaultButton from './DefaultButton'
import ButtonWithIcon from './ButtonWithIcon'

function SavedSearchesDropdown({ savedSearches }) {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const handleClose = () => {
    setShowForm(false)
  }

  const handleOpen = e => {
    setShowForm(true)
  }

  const handleApply = search => {
    if (search.searchUrl) {
      const savedUrl = new URL(search.searchUrl)
      dispatch(resetGiftSearch)
      dispatch(syncGiftSearchURL(savedUrl.search))
      dispatch(updateGiftSearchURL)
    }
    handleClose()
  }

  const handleDelete = search => {
    dispatch(deleteSavedSearch(search.id))
    dispatch(getSavedSearches({ type: 'gift' }))
  }

  return (
    <div className={`ge-search-field-wrapper tw-flex tw-gap-2 tw-mt-2`}>
      <ButtonWithIcon
        onClick={handleOpen}
        color="grey"
        label={t.explorer.your_saved_searches}
        Icon={Star}
      />
      <Dialog
        open={showForm}
        onClose={handleClose}
        onRequestClose={handleClose}
        onBackdropClick={handleClose}
        className="Explorer"
      >
        {savedSearches.count === 0 ? (
          <p>{t.explorer.no_saved_searches}</p>
        ) : (
          <div>
            <div className="tw-mb-5">
              <p className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold tw-text-md">
                {t.explorer.use_saved_search}
              </p>
              {savedSearches.results.map((search) => {
                return (
                  <div key={search.id} className="tw-flex tw-justify-between">
                    <p className="tw-text-md">{search.name}</p>
                    <div className="tw-inline">
                      <DefaultButton onClick={() => handleApply(search)} label={t.explorer.apply} />
                      <FlatButton
                        onClick={() => handleDelete(search)}
                        className={`button-link tw-ml-1`}
                      >
                        <div className="tw-inline-flex tw-px-2 tw-items-center">
                          <span className="tw-underline tw-text-md">{t.explorer.delete}</span>
                        </div>
                      </FlatButton>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default SavedSearchesDropdown
