import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, FlatButton } from 'material-ui'
import { FilterList } from 'material-ui-icons'

import { setGiftSearchFilterByKey } from 'store/actions/giftSearch'
import { selectGiftSearchFilters } from 'store/selectors/giftSearch'

import DefaultButton from './DefaultButton'
import SimpleInputField from './SimpleInputField'

const RegionHeader = ({ column }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [showForm, setShowForm] = useState(false)

  const filters = useSelector(selectGiftSearchFilters)
  const isActive = !!filters['city']

  const handleClose = () => {
    setShowForm(false)
  }

  const handleOpen = e => {
    e.stopPropagation()
    setShowForm(true)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    dispatch(setGiftSearchFilterByKey(Object.fromEntries(formData.entries())))
    handleClose()
  }

  return (
    <div>
      <FlatButton
        title="Gift recipient location"
        onClick={handleOpen}
        className={`ge-header-button ${isActive ? 'active' : ''}`}
      >
        <div className="tw-inline-flex tw-items-center tw-text-dark">
          <FilterList />
          <span className="tw-ml-1">{column.columnDef.header}</span>
        </div>
      </FlatButton>
      <Dialog
        open={showForm}
        onClose={handleClose}
        onRequestClose={handleClose}
        onBackdropClick={handleClose}
        className="Explorer"
      >
        <form onSubmit={handleSubmit}>
          <div className="tw-mb-4">
            <label
              htmlFor="city"
              className="tw-w-full tw-block tw-mb-2 tw-text-md tw-text-black tw-font-semibold"
            >
              {t.explorer.search_locations}
            </label>
            <SimpleInputField
              type="text"
              id="city"
              placeholder={t.explorer.search_locations_placeholder}
              defaultValue={filters['city']}
            />
          </div>

          <div className="tw-pt-3 tw-flex tw-justify-end tw-flex-none tw-border tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-grey">
            <DefaultButton type="submit" label={t.explorer.apply} />
          </div>
        </form>
      </Dialog>
    </div>
  )
}

export default RegionHeader
