import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MaterialReactTable } from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { ArrowDownward } from 'material-ui-icons'
import { useTranslation } from 'react-i18next'

import { setGiftSearchPagination, setGiftSearchSorting } from 'store/actions/giftSearch'

import FullTextModal from './FullTextModal'
import FunderHeader from './FunderHeader'
import FunderCell from './FunderCell'
import CharityCell from './CharityCell'
import CharityHeader from './CharityHeader'
import AmountHeader from './AmountHeader'
import FocusHeader from './FocusHeader'
import LocationHeader from './LocationHeader'
import YearHeader from './YearHeader'
import PurposeHeader from './PurposeHeader'

const DataTable = ({ records, count }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const { loading, pagination, sorting } = useSelector(({ giftSearch }) => giftSearch)

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'charity.name',
        header: t.explorer.recipient,
        size: 110,
        Header: ({ column }) => {
          return (
            <CharityHeader column={column} />
          )
        },
        Cell: ({ cell }) => <CharityCell cell={cell} />
      },
      {
        accessorKey: 'funder.name', //access nested data with dot notation
        header: t.explorer.funder,
        size: 110,
        Header: ({ column }) => {
          return (
            <FunderHeader column={column} />
          )
        },
        Cell: ({ cell }) => <FunderCell cell={cell} />
      },
      {
        accessorKey: 'giftAmount',
        header: t.explorer.amount,
        size: 110,
        Header: ({ column }) => {
          return (
            <AmountHeader column={column} />
          )
        },
        Cell: ({ cell }) => {
          const numberValue = cell.getValue()
          const formattedValue = new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          }).format(numberValue)

          return <div>{formattedValue}</div>
        }
      },
      {
        accessorKey: 'focus',
        header: t.explorer.focus,
        size: 110,
        Header: ({ column }) => {
          return (
            <FocusHeader column={column} />
          )
        }
      },
      {
        accessorKey: 'city',
        header: t.explorer.location,
        size: 110,
        Header: ({ column }) => {
          return (
            <LocationHeader column={column} />
          )
        }
      },
      {
        accessorKey: 'year',
        header: t.explorer.year,
        size: 110,
        Header: ({ column }) => {
          return (
            <YearHeader column={column} />
          )
        }
      },
      {
        accessorKey: 'details',
        header: t.explorer.description,
        size: 110,
        enableSorting: false,
        Cell: ({ cell }) => {
          const fullText = cell.getValue()
          const maxLength = 50

          if (!fullText) {
            return <div>{`-`}</div>
          }
          if (fullText.length > maxLength) {
            return <FullTextModal fullText={fullText} />
          }

          return <div>{fullText}</div>
        },
        Header: ({ column }) => {
          return (
            <PurposeHeader column={column} />
          )
        }
      }
    ],
    []
  )

  const customLocalizationEn = {
    ...MRT_Localization_EN,
    showAllColumns: 'Unhide all columns',
    move: 'Move column'
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={records}
      enableColumnOrdering
      enableTopToolbar={false}
      enableColumnFilters={false}
      muiTableProps={{
        sx: {
          borderRight: '1px solid #D9E3E9',
          borderTop: '1px solid #D9E3E9'
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          borderLeft: '1px solid #D9E3E9',
          borderBottom: '1px solid #D9E3E9',
          paddingRight: '0.4rem'
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          borderLeft: '1px solid #D9E3E9',
          borderBottom: '1px solid #D9E3E9',
          paddingRight: '0.4rem'
        }
      }}
      localization={customLocalizationEn}
      icons={{
        SortIcon: props => <ArrowDownward {...props} />
      }}
      manualPagination
      manualSorting
      onPaginationChange={update => dispatch(setGiftSearchPagination(update(pagination)))}
      onSortingChange={update => dispatch(setGiftSearchSorting(update(sorting)))}
      rowCount={count}
      state={{
        isLoading: loading,
        pagination,
        sorting,
      }}
    />
  )
}

export default DataTable
