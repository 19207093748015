import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paper } from 'material-ui'
import { Undo, Close, ArrowForward } from 'material-ui-icons'

import {
  debouncedGetGiftStats,
  debouncedSearchGifts,
  resetGiftSearch,
  setGiftSearchFilterByKey,
  setGiftSearchFilters,
  syncGiftSearchURL,
} from 'store/actions/giftSearch'
import { getSavedSearches } from 'store/actions/savedSearches'
import { selectSavedGiftSearches } from 'store/selectors/savedSearch'

import SearchBar from './components/SearchBar'
import SavedSearchesDropdown from './components/SavedSearchesDropdown'
import SaveSearch from './components/SaveSearch'
import DataTable from './components/DataTable'
import ButtonWithIcon from './components/ButtonWithIcon'
import ResultsSummary from './components/ResultsSummary'
import BarChart from './components/BarChart'
import PieChart from './components/PieChart'

const Explorer = () => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()
  const location = useLocation()

  const [filterStack, setFilterStack] = useState([])

  const {
    urlSynced,
    pagination,
    sorting,
    filters,
    results,
    stats,
  } = useSelector(({ giftSearch }) => giftSearch)

  const savedSearches = useSelector(selectSavedGiftSearches)

  const updateFilterStack = () => {
    const lastInStack = filterStack[filterStack.length - 1]
    if (
      (filterStack.length === 0 && !_.isEmpty(filters)) ||
      (filterStack.length > 0 && !_.isEqual(lastInStack, filters))
    ) {
      setFilterStack([...filterStack, filters])
    }
  }

  const reset = () => {
    dispatch(resetGiftSearch)
  }

  const handleRemoveFilter = filterKey => {
    dispatch(setGiftSearchFilterByKey({ [filterKey]: null }))
  }

  const undo = () => {
    const stack = [...filterStack]
    if (stack.length > 0) {
      stack.pop()
      setFilterStack(stack)
      dispatch(setGiftSearchFilters(stack[stack.length - 1] || {}))
    }
  }

  const totalSum = results.count === 10000 ? "10000+" : results.count
  const formattedAmount = new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(stats.giftAmountTotal || 0)

  useEffect(() => {
    updateFilterStack()
  }, [filters])

  useEffect(() => {
    if (!urlSynced) {
      dispatch(syncGiftSearchURL(location.search))
    }
    dispatch(debouncedSearchGifts)
  }, [pagination, sorting, filters])

  useEffect(() => {
    dispatch(debouncedGetGiftStats)
  }, [filters])

  useEffect(() => {
    dispatch(getSavedSearches({ type: 'gift' }))
  }, [])

  return (
    <div className="Explorer">
      <Grid fluid className="grid">
        <Row>
          <Col xs={12}>
            <h1 className={'tw-text-2xl'}>{t.explorer.title}</h1>
            <p className="subtitle tw-mb-6">{t.explorer.subtitle}</p>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>{t.explorer.search}</h2>
              <SearchBar />
              <div className="">
                <SavedSearchesDropdown savedSearches={savedSearches} />
              </div>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <div className="sm:tw-flex tw-justify-between tw-mb-1">
                <h2 className={'tw-text-lg tw-font-semibold tw-mb-1 tw-mt-0'}>
                  {t.explorer.results}
                </h2>
                <div className="tw-flex tw-gap-1 tw-flex-wrap tw-mb-2">
                  <SaveSearch savedSearches={savedSearches} />
                  <ButtonWithIcon
                    onClick={undo}
                    disabled={filterStack.length === 0}
                    color="grey"
                    label="Undo"
                    Icon={Undo}
                  />
                  <ButtonWithIcon onClick={reset} color="dark-grey" label="Reset" Icon={Close} />
                </div>
              </div>
              <ResultsSummary filters={filters} handleRemoveFilter={handleRemoveFilter} />
              <div>
                <div className="tw-flex tw-justify-end tw-items-center tw-gap-1 tw-mb-1">
                  <span>{t.explorer.scroll}</span>
                  <ArrowForward style={{ height: '18px', width: '18px' }} />
                </div>
                <DataTable records={results.records} count={results.count} />
                <div className="tw-mt-4 tw-flex tw-gap-4">
                  <div className="tw-text-center">
                    <p className="tw-my-1">{`Total number of gifts`}</p>
                    <p className="tw-my-0 tw-font-semibold tw-text-lg">{totalSum}</p>
                  </div>
                  <div className="tw-text-center">
                    <p className="tw-my-1">{`Total amount of gifts`}</p>
                    <p className="tw-my-0 tw-font-semibold tw-text-lg">{formattedAmount}</p>
                  </div>
                </div>
              </div>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>
                {t.explorer.graphic_1_title}
              </h2>
              <BarChart />
            </Paper>
          </Col>
          <Col xs={12} md={6}>
            <Paper elevation={1} className={`Material-cards Material-cards__expanded`}>
              <h2 className={'tw-text-lg tw-font-semibold tw-mt-0'}>
                {t.explorer.graphic_2_title}
              </h2>
              <PieChart />
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="tw-my-6">{t.explorer.disclaimer}</p>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default Explorer
