import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsLibraryMode } from 'store/selectors/user'
import Explorer from 'components/Explorer'
import { redirectTo } from 'store/actions/user'
import { useHotjar } from 'utils/hotjar'

const GiftExplorer = () => {
  const dispatch = useDispatch()
  const isLibraryMode = useSelector(selectIsLibraryMode)

  useEffect(() => {
    if (isLibraryMode) {
      dispatch(redirectTo('/search'))
    }
  }, [isLibraryMode])

  useHotjar()

  return <Explorer />
}

export default GiftExplorer
