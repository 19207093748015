import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { selectGiftSearchStats } from 'store/selectors/giftSearch'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ ...props }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  
  const stats = useSelector(selectGiftSearchStats)

  const labels = stats.giftAmountByRecipient?.map(charity =>
    charity.name.length > 23
      ? `${charity.name.substring(0, 25)}…`
      : charity.name
  ) || []
  const series = stats.giftAmountByRecipient?.map(charity => charity.total) || []

  const data = {
    labels: labels,
    datasets: [
      {
        label: t.explorer.total_amount_received,
        data: series,
        borderWidth: 1,
        backgroundColor: ['#f5b400', '#ffc72c', '#ffe085']
      }
    ]
  }

  const options = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return <Pie options={options} data={data} {...props} />
}

export default PieChart
