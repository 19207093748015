import { debounce } from 'lodash'

import backend from 'utils/backend'
import history from 'utils/history'

export const GIFT_SEARCH_SET_URL_SYNCED = 'GIFT_SEARCH_SET_URL_SYNCED'
export const GIFT_SEARCH_SET_LOADING_STATE = 'GIFT_SEARCH_SET_LOADING_STATE'
export const GIFT_SEARCH_UPDATE_RESULTS = 'GIFT_SEARCH_UPDATE_RESULTS'
export const GIFT_SEARCH_UPDATE_STATS = 'GIFT_SEARCH_UPDATE_STATS'
export const GIFT_SEARCH_SET_PAGINATION = 'GIFT_SEARCH_SET_PAGINATION'
export const GIFT_SEARCH_SET_SORTING = 'GIFT_SEARCH_SET_SORTING'
export const GIFT_SEARCH_SET_FILTERS = 'GIFT_SEARCH_SET_FILTERS'
export const GIFT_SEARCH_SET_FILTER_BY_KEY = 'GIFT_SEARCH_SET_FILTER_BY_KEY'
export const GIFT_SEARCH_RESET = 'GIFT_SEARCH_CLEAR_FILTERS'
export const GIFT_SEARCH_FAILURE = 'GIFT_SEARCH_FAILURE'

const FILTER_MAPPINGS = {
  'funder': 'funder_name',
  'charity': 'charity_name',
  'recipientSizeMin': 'recipient_size__gte',
  'recipientSizeMax': 'recipient_size__lte',
  'giftAmountMin': 'gift_amount__gte',
  'giftAmountMax': 'gift_amount__lte',
  'yearMin': 'year__gte',
  'yearMax': 'year__lte',
  'keyword': 'search',
  'focus': 'focus',
  'city': 'city',
  'details': 'details',
}

const REVERSE_FILTER_MAPPINGS = Object.fromEntries(Object.entries(FILTER_MAPPINGS).map(([k, v]) => [v, k]))

const serializeGiftSearchState = ({ giftSearch }) => {
  const { pagination: { pageIndex, pageSize }, sorting, filters } = giftSearch
  const pagination = `limit=${pageSize}&offset=${pageIndex * pageSize}`
  const ordering = sorting.length > 0 ? `&ordering=${sorting[0].desc ? "-" : ""}${sorting[0].id}` : ""
  const filtering = Object.entries(filters).map(([filterKey, filterValue]) => (
    `&${_.get(FILTER_MAPPINGS, filterKey, filterKey)}=` +
    `${filterValue instanceof Array ? filterValue.join('__') : filterValue}`
  )).join('')
  return `?${pagination}${ordering}${filtering}`
}

export const searchGifts = async (dispatch, getState) => {
  dispatch({ type: GIFT_SEARCH_SET_LOADING_STATE, payload: { loading: true } })

  const searchParams = serializeGiftSearchState(getState())

  try {
    const { data: { count, results } = {} } = await backend.get(`api/search/gifts/${searchParams}`)
    dispatch({ type: GIFT_SEARCH_UPDATE_RESULTS, payload: { count, records: results }})
  } catch (error) {
    dispatch({ type: GIFT_SEARCH_FAILURE, payload: { error } })
  }

  dispatch({ type: GIFT_SEARCH_SET_LOADING_STATE, payload: { loading: false } })
}

export const debouncedSearchGifts = debounce(dispatch => {
  dispatch(searchGifts)
}, 500)

export const getGiftStats = async (dispatch, getState) => {
  const searchParams = serializeGiftSearchState(getState())

  try {
    const { data } = await backend.get(`api/search/giftstats/${searchParams}`)
    dispatch({ type: GIFT_SEARCH_UPDATE_STATS, payload: { stats: data } })
  }
  catch (error) {
    console.log({ error })
  }
}

export const debouncedGetGiftStats = debounce(dispatch => {
  dispatch(getGiftStats)
}, 500)

export const updateGiftSearchURL = (dispatch, getState) => {
  history.push(`/gift${serializeGiftSearchState(getState())}`)
}

export const syncGiftSearchURL = params => dispatch => {
  const searchParams = new URLSearchParams(params)

  const pagination = { pageIndex: 0, pageSize: 10 }
  if (searchParams.has('limit')) {
    pagination.pageSize = +searchParams.get('limit')
  }
  if (searchParams.has('offset')) {
    pagination.pageIndex = Math.floor(+searchParams.get('offset') / pagination.pageSize)
  }
  dispatch({ type: GIFT_SEARCH_SET_PAGINATION, payload: { pagination } })

  const sorting = []
  if (searchParams.has('ordering')) {
    const ordering = searchParams.get('ordering')
    const desc = ordering.startsWith("-")
    sorting.push({ id: ordering.substring(desc ? 1 : 0), desc })
  }
  dispatch({ type: GIFT_SEARCH_SET_SORTING, payload: { sorting } })

  const filters = {}
  for (const [key, value] of searchParams) {
    if (!_.has(REVERSE_FILTER_MAPPINGS, key)) {
      continue
    }

    const filter = REVERSE_FILTER_MAPPINGS[key]
    if (filter === 'focus') {
      filters[filter] = value.split("__")
    } else {
      filters[filter] = value
    }
  }
  dispatch({ type: GIFT_SEARCH_SET_FILTER_BY_KEY, payload: { filtersByKey: filters } })

  dispatch({ type: GIFT_SEARCH_SET_URL_SYNCED })
}

export const setGiftSearchPagination = pagination => dispatch => {
  dispatch({ type: GIFT_SEARCH_SET_PAGINATION, payload: { pagination } })
  dispatch(updateGiftSearchURL)
}

export const setGiftSearchSorting = sorting => dispatch => {
  dispatch({ type: GIFT_SEARCH_SET_SORTING, payload: { sorting } })
  dispatch(updateGiftSearchURL)
}

export const setGiftSearchFilters = filters => dispatch => {
  dispatch({ type: GIFT_SEARCH_SET_FILTERS, payload: { filters } })
  dispatch(updateGiftSearchURL)
}

export const setGiftSearchFilterByKey = filters => dispatch => {
  dispatch({ type: GIFT_SEARCH_SET_FILTER_BY_KEY, payload: { filtersByKey: { ...filters } } })
  dispatch(updateGiftSearchURL)
}

export const resetGiftSearch = (dispatch, getState) => {
  const { giftSearch: { sorting, filters } } = getState()
  if (sorting.length === 0 && Object.keys(filters).length === 0) {
    return
  }

  dispatch({ type: GIFT_SEARCH_RESET })
  dispatch(updateGiftSearchURL)
}
