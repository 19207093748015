import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import DefaultButton from './DefaultButton'
import { setGiftSearchFilterByKey } from 'store/actions/giftSearch'

const SearchBar = () => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)
  const dispatch = useDispatch()

  const [localText, setLocalText] = useState("")
  const [autocompleteResults, setAutocompleteResults] = useState([])
  const [autocompleteValue, setAutocompleteValue] = useState(null)

  const handleSelect = e => {
    dispatch(setGiftSearchFilterByKey({ keyword: localText }))

    setLocalText("")
    setAutocompleteResults([])
    setAutocompleteValue(null)
  }

  const handleAutocompleteSelect = (e, value) => {
    if (value?.field === "Keyword") {
      dispatch(setGiftSearchFilterByKey({ keyword: value.match }))
    }
    if (value?.field === "Recipient") {
      dispatch(setGiftSearchFilterByKey({ charity: value.match }))
    }
    if (value?.field === "Funder") {
      dispatch(setGiftSearchFilterByKey({ funder: value.match }))
    }

    setLocalText("")
    setAutocompleteResults([])
    setAutocompleteValue(null)
  }

  const handleSearchChange = (e, value, reason) => {
    if (reason === "reset") {
      return
    }

    // The value param appears to be broken when pressing the enter key,
    // so use the event target's value instead.
    const query = e.target.value || ""
    setLocalText(query)

    if (query.length > 2) {
      setAutocompleteResults([
        { field: "Keyword", match: query },
        // TODO: implement proper autocomplete
        { field: "Funder", match: query },
        { field: "Charity", match: query },
      ])
    }
  }

  return (
    <div className="relative">
      <div className={`ge-search-field-wrapper tw-flex tw-gap-2 tw-items-stretch`}>
        <Autocomplete
          id="gifts-search-bar"
          fullWidth
          style={{ maxWidth: '520px' }}
          freeSolo
          size="small"
          color="secondary"
          filterOptions={x => x}
          options={autocompleteResults}
          renderOption={(props, option) => {
            const { key, id, ...optionProps } = props
            return (
              <li
                key={id}
                {...optionProps}
                className="tw-flex tw-gap-1 tw-p-2 tw-pl-3 hover:tw-bg-lighterGrey"
              >
                <span className="tw-text-darkGrey">{`${option.field}:`}</span>
                <span>{option.match}</span>
              </li>
            )
          }}
          autoHighlight
          onChange={handleAutocompleteSelect}
          value={autocompleteValue}
          getOptionLabel={option => `${option.match}`}
          autoComplete
          filterSelectedOptions
          noOptionsText={t.explorer.no_results}
          onInputChange={handleSearchChange}
          inputValue={localText}
          renderInput={params => <TextField {...params} placeholder={t.explorer.search_placeholder} />}
        />
        <DefaultButton onClick={handleSelect} label={t.explorer.search} />
      </div>
    </div>
  )
}

export default SearchBar
